<template>
  <Header></Header>
  <div>
    <div class="bg-gray">
      <div class="gray-container">
        <div class="part-title">当前暂无该系统的访问权限！</div>
        <el-row :gutter="20" justify="center">
          <el-col :md="8">
            <module :item="noAuthPart"></module>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="container">
      <div class="part-title">有权限的系统</div>
      <el-row :gutter="20" justify="center">
        <el-col v-for="(item, index) in haveAuthList" :key="index" :md="8">
          <module :item="item"></module>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import module from "@/components/module.vue";
import { onMounted } from "vue";
import { useRouter } from "vue-router";
import { localStore, moduleInfos, _checkToken } from "@/utils/utils.js";
export default {
  components: { Header, module },
  setup() {
    const router = useRouter();
    /* const state = reactive({
      haveAuthList: [],
      noAuthPart: [],
    }); */
    onMounted(() => {
      _checkToken();
      /* alert(34342344);
      
      alert(34342344); */
      //setModule();
    });
    // 获取登录信息
    //var log_id = localStore("loginId");
    var infos = JSON.parse(localStore("userAppRoles"));
    // 获取无权限的系统code
    var authCode = router.currentRoute.value.query.appCode;
    const noAuthPart = moduleInfos[authCode];
    // 获取有权限的系统信息
    var haveAuthList = [];
    for (var i = 0; i < infos.length; i++) {
      haveAuthList.push(moduleInfos[infos[i]["appCode"]]);
    }
    return {
      noAuthPart,
      haveAuthList,
    };
  },
};
</script>

<style scoped>
.part-title {
  font-size: 34px;
  padding: 20px 0;
  color: #0c0c0c;
  text-align: center;
  font-weight: bold;
}
.gray-container {
  width: 80%;
  margin: 0 auto;
  padding: 40px;
  justify-content: center;
}
</style>
